// Profile
export const contentStep = (state) => state.contentStep;
export const profile = (state) => state.profile;
export const profileLoading = (state) => state.profileLoading;
export const profileError = (state) => state.profileError;
export const showProfileEditModal = (state) => state.showProfileEditModal;
export const createProfileRes = (state) => state.createProfile;
export const hasSeenWTPModalRes = (state) => state.hasSeenWTPModal;
export const deleteProfileRes = (state) => state.deleteProfile;
export const blocks = (state) => state.blocks;

// Profile Feed
export const feed = (state) => state.feed;
export const feedLoading = (state) => state.feedLoading;
export const feedError = (state) => state.feedError;
export const nextPostLoading = (state) => state.nextPostLoading;

// Profile Followers
export const followers = (state) => state.followers;
export const followersLoading = (state) => state.followersLoading;
export const nextFollowersLoading = (state) => state.nextFollowersLoading;
export const followersError = (state) => state.followersError;
export const showLoadMoreFollowers = (state) => state.showLoadMoreFollowers;

// Profile Followed
export const followed = (state) => state.followed;
export const followedLoading = (state) => state.followedLoading;
export const nextFollowedLoading = (state) => state.nextFollowedLoading;
export const followedError = (state) => state.followedError;
export const showLoadMoreFollowed = (state) => state.showLoadMoreFollowed;
export const profileFollowedCountPerTypeRes = (state) =>
  state.followedCountPerType;
