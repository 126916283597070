export const createAdminLeagueRes = (state) => state.createAdminLeague;
export const leagues = (state) => state.leagues;
export const moreLeaguesLoading = (state) => state.moreLeaguesLoading;
export const leaderboard = (state) => state.leaderboard;
export const pendingProfileInvitationsRes = (state) =>
  state.pendingProfileInvitations;
export const answerLeagueInvitationRes = (state) =>
  state.answerLeagueInvitation;
export const league = (state) => state.league;
export const leagueRules = (state) => state.leagueRules;
export const profileLeaderboard = (state) => state.profileLeaderboard;
export const nextProfileLeaderboard = (state) => state.profileLeaderboard;
