export default {
  createAdminLeague(context, payload) {
    context.commit('CREATE_ADMIN_LEAGUE_INIT');
    return this.dispatch('api/createAdminLeague', payload)
      .then((res) => {
        context.commit('CREATE_ADMIN_LEAGUE_SUCCESS', res.data);
        context.commit('APPEND_LEAGUES_TO_LEAGUES', res.data);
      })
      .catch((err) => {
        context.commit('CREATE_ADMIN_LEAGUE_ERROR', err);
      });
  },
  getLeagues(context, query) {
    context.commit('GET_LEAGUES_INIT');
    return this.dispatch('api/searchLeagues', query)
      .then((res) => {
        context.commit('GET_LEAGUES_SUCCESS', res.data);
      })
      .catch((err) => {
        context.commit('GET_LEAGUES_ERROR', err);
      });
  },
  getPendingProfileInvitations(context) {
    context.commit('GET_PENDING_INVITATIONS_INIT');
    return this.dispatch('api/getPendingProfileInvitations')
      .then((res) => {
        context.commit('APPEND_PENDING_PROFILE_INVITATIONS', res.data);
      })
      .catch((err) => {
        context.commit('GET_PENDING_INVITATIONS_ERROR', err);
      });
  },
  answerLeagueInvitation(context, payload) {
    return this.dispatch('api/answerLeagueInvitation', payload)
      .then(() => {
        context.commit('REMOVE_ANSWERED_INVITATION', payload);
      })
      .catch((err) => {
        context.commit('ANSWER_LEAGUE_INVITATION_ERROR', err);
      });
  },
  getLeagueById(context, payload) {
    context.commit('GET_LEAGUE_BY_ID_INIT');
    return this.dispatch('api/getLeagueById', payload)
      .then((res) => {
        context.commit('GET_LEAGUE_BY_ID_SUCCESS', res.data);
      })
      .catch((err) => {
        context.commit('GET_LEAGUE_BY_ID_ERROR', err);
      });
  },
  getLeagueRulesById(context, payload) {
    context.commit('GET_LEAGUE_RULES_BY_ID_INIT');
    return this.dispatch('api/getLeagueRulesById', payload)
      .then((res) => {
        context.commit('GET_LEAGUE_RULES_BY_ID_SUCCESS', res.data);
      })
      .catch((err) => {
        context.commit('GET_LEAGUE_RULES_BY_ID_ERROR', err);
      });
  },
  getProfileLeaderboard(context, payload) {
    context.commit('GET_PROFILE_LEADERBOARD_INIT');
    return this.dispatch('api/getProfileLeaderboard', payload)
      .then((res) => {
        context.commit('GET_PROFILE_LEADERBOARD_SUCCESS', res.data);
      })
      .catch((err) => {
        context.commit('GET_PROFILE_LEADERBOARD_ERROR', err);
      });
  },
  getNextProfileLeaderboard(context, payload) {
    context.commit('GET_NEXT_PROFILE_LEADERBOARD_INIT');
    return this.dispatch('api/getProfileLeaderboard', payload)
      .then((res) => {
        context.commit('GET_NEXT_PROFILE_LEADERBOARD_SUCCESS', res.data);
      })
      .catch((err) => {
        context.commit('GET_NEXT_PROFILE_LEADERBOARD_ERROR', err);
      });
  },
  followLeaderboardProfile(context, profileId) {
    return this.dispatch('api/followProfile', profileId).then((res) => {
      context.commit('UPDATE_LEADERBOARD_IS_FOLLOWED', res.data);
    });
  },
};
