export const Button = () => import('../../components/ui/atom/buttons/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c))
export const Dropdown = () => import('../../components/ui/atom/buttons/Dropdown.vue' /* webpackChunkName: "components/dropdown" */).then(c => wrapFunctional(c.default || c))
export const FollowBtn = () => import('../../components/ui/atom/buttons/FollowBtn.vue' /* webpackChunkName: "components/follow-btn" */).then(c => wrapFunctional(c.default || c))
export const FollowBtnV2 = () => import('../../components/ui/atom/buttons/FollowBtnV2.vue' /* webpackChunkName: "components/follow-btn-v2" */).then(c => wrapFunctional(c.default || c))
export const FollowPriceChangeBtn = () => import('../../components/ui/atom/buttons/FollowPriceChangeBtn.vue' /* webpackChunkName: "components/follow-price-change-btn" */).then(c => wrapFunctional(c.default || c))
export const RoundedFollowBtn = () => import('../../components/ui/atom/buttons/RoundedFollowBtn.vue' /* webpackChunkName: "components/rounded-follow-btn" */).then(c => wrapFunctional(c.default || c))
export const ShowCreatePostRounded = () => import('../../components/ui/atom/buttons/ShowCreatePostRounded.vue' /* webpackChunkName: "components/show-create-post-rounded" */).then(c => wrapFunctional(c.default || c))
export const ChartSkeleton = () => import('../../components/ui/atom/skeleton/ChartSkeleton.vue' /* webpackChunkName: "components/chart-skeleton" */).then(c => wrapFunctional(c.default || c))
export const CommentSkeleton = () => import('../../components/ui/atom/skeleton/CommentSkeleton.vue' /* webpackChunkName: "components/comment-skeleton" */).then(c => wrapFunctional(c.default || c))
export const FundamentalsSkeleton = () => import('../../components/ui/atom/skeleton/FundamentalsSkeleton.vue' /* webpackChunkName: "components/fundamentals-skeleton" */).then(c => wrapFunctional(c.default || c))
export const ItemSkeleton = () => import('../../components/ui/atom/skeleton/ItemSkeleton.vue' /* webpackChunkName: "components/item-skeleton" */).then(c => wrapFunctional(c.default || c))
export const MentionCardSkeleton = () => import('../../components/ui/atom/skeleton/MentionCardSkeleton.vue' /* webpackChunkName: "components/mention-card-skeleton" */).then(c => wrapFunctional(c.default || c))
export const MobileStartPostSkeleton = () => import('../../components/ui/atom/skeleton/MobileStartPostSkeleton.vue' /* webpackChunkName: "components/mobile-start-post-skeleton" */).then(c => wrapFunctional(c.default || c))
export const NewsSkeleton = () => import('../../components/ui/atom/skeleton/NewsSkeleton.vue' /* webpackChunkName: "components/news-skeleton" */).then(c => wrapFunctional(c.default || c))
export const OperationRowSkeleton = () => import('../../components/ui/atom/skeleton/OperationRowSkeleton.vue' /* webpackChunkName: "components/operation-row-skeleton" */).then(c => wrapFunctional(c.default || c))
export const PortfolioCardSkeleton = () => import('../../components/ui/atom/skeleton/PortfolioCardSkeleton.vue' /* webpackChunkName: "components/portfolio-card-skeleton" */).then(c => wrapFunctional(c.default || c))
export const PortfolioFeedMobileSkeleton = () => import('../../components/ui/atom/skeleton/PortfolioFeedMobileSkeleton.vue' /* webpackChunkName: "components/portfolio-feed-mobile-skeleton" */).then(c => wrapFunctional(c.default || c))
export const PortfolioFeedSkeleton = () => import('../../components/ui/atom/skeleton/PortfolioFeedSkeleton.vue' /* webpackChunkName: "components/portfolio-feed-skeleton" */).then(c => wrapFunctional(c.default || c))
export const PortfolioIdSkeleton = () => import('../../components/ui/atom/skeleton/PortfolioIdSkeleton.vue' /* webpackChunkName: "components/portfolio-id-skeleton" */).then(c => wrapFunctional(c.default || c))
export const PortfolioTableSkeleton = () => import('../../components/ui/atom/skeleton/PortfolioTableSkeleton.vue' /* webpackChunkName: "components/portfolio-table-skeleton" */).then(c => wrapFunctional(c.default || c))
export const PostSkeleton = () => import('../../components/ui/atom/skeleton/PostSkeleton.vue' /* webpackChunkName: "components/post-skeleton" */).then(c => wrapFunctional(c.default || c))
export const PriceCardSkeleton = () => import('../../components/ui/atom/skeleton/PriceCardSkeleton.vue' /* webpackChunkName: "components/price-card-skeleton" */).then(c => wrapFunctional(c.default || c))
export const ProfileSkeleton = () => import('../../components/ui/atom/skeleton/ProfileSkeleton.vue' /* webpackChunkName: "components/profile-skeleton" */).then(c => wrapFunctional(c.default || c))
export const RankingsSkeleton = () => import('../../components/ui/atom/skeleton/RankingsSkeleton.vue' /* webpackChunkName: "components/rankings-skeleton" */).then(c => wrapFunctional(c.default || c))
export const RecoSkeleton = () => import('../../components/ui/atom/skeleton/RecoSkeleton.vue' /* webpackChunkName: "components/reco-skeleton" */).then(c => wrapFunctional(c.default || c))
export const StartPostSkeleton = () => import('../../components/ui/atom/skeleton/StartPostSkeleton.vue' /* webpackChunkName: "components/start-post-skeleton" */).then(c => wrapFunctional(c.default || c))
export const StockSkeleton = () => import('../../components/ui/atom/skeleton/StockSkeleton.vue' /* webpackChunkName: "components/stock-skeleton" */).then(c => wrapFunctional(c.default || c))
export const TickerCardSkeletonMobile = () => import('../../components/ui/atom/skeleton/TickerCardSkeletonMobile.vue' /* webpackChunkName: "components/ticker-card-skeleton-mobile" */).then(c => wrapFunctional(c.default || c))
export const TickerChartSkeletonMobile = () => import('../../components/ui/atom/skeleton/TickerChartSkeletonMobile.vue' /* webpackChunkName: "components/ticker-chart-skeleton-mobile" */).then(c => wrapFunctional(c.default || c))
export const TrendingHeadlinesSkeleton = () => import('../../components/ui/atom/skeleton/TrendingHeadlinesSkeleton.vue' /* webpackChunkName: "components/trending-headlines-skeleton" */).then(c => wrapFunctional(c.default || c))
export const TrendingIndexSkeleton = () => import('../../components/ui/atom/skeleton/TrendingIndexSkeleton.vue' /* webpackChunkName: "components/trending-index-skeleton" */).then(c => wrapFunctional(c.default || c))
export const UIProfileLeaderboardSkeleton = () => import('../../components/ui/atom/skeleton/UIProfileLeaderboardSkeleton.vue' /* webpackChunkName: "components/u-i-profile-leaderboard-skeleton" */).then(c => wrapFunctional(c.default || c))
export const UserSkeleton = () => import('../../components/ui/atom/skeleton/UserSkeleton.vue' /* webpackChunkName: "components/user-skeleton" */).then(c => wrapFunctional(c.default || c))
export const MyFormulateMultiSelect = () => import('../../components/ui/atom/vueFormulateCustoms/MyFormulateMultiSelect.vue' /* webpackChunkName: "components/my-formulate-multi-select" */).then(c => wrapFunctional(c.default || c))
export const MyFormulateTextarea = () => import('../../components/ui/atom/vueFormulateCustoms/MyFormulateTextarea.vue' /* webpackChunkName: "components/my-formulate-textarea" */).then(c => wrapFunctional(c.default || c))
export const MyFormulateToggle = () => import('../../components/ui/atom/vueFormulateCustoms/MyFormulateToggle.vue' /* webpackChunkName: "components/my-formulate-toggle" */).then(c => wrapFunctional(c.default || c))
export const Banner = () => import('../../components/ui/atom/Banner.vue' /* webpackChunkName: "components/banner" */).then(c => wrapFunctional(c.default || c))
export const Card = () => import('../../components/ui/atom/Card.vue' /* webpackChunkName: "components/card" */).then(c => wrapFunctional(c.default || c))
export const ChangePercent = () => import('../../components/ui/atom/ChangePercent.vue' /* webpackChunkName: "components/change-percent" */).then(c => wrapFunctional(c.default || c))
export const ChangePlus = () => import('../../components/ui/atom/ChangePlus.vue' /* webpackChunkName: "components/change-plus" */).then(c => wrapFunctional(c.default || c))
export const DateFromNow = () => import('../../components/ui/atom/DateFromNow.vue' /* webpackChunkName: "components/date-from-now" */).then(c => wrapFunctional(c.default || c))
export const Dot = () => import('../../components/ui/atom/Dot.vue' /* webpackChunkName: "components/dot" */).then(c => wrapFunctional(c.default || c))
export const FilterTag = () => import('../../components/ui/atom/FilterTag.vue' /* webpackChunkName: "components/filter-tag" */).then(c => wrapFunctional(c.default || c))
export const HelpButton = () => import('../../components/ui/atom/HelpButton.vue' /* webpackChunkName: "components/help-button" */).then(c => wrapFunctional(c.default || c))
export const HelpLink = () => import('../../components/ui/atom/HelpLink.vue' /* webpackChunkName: "components/help-link" */).then(c => wrapFunctional(c.default || c))
export const HorizontaleSectionSelector = () => import('../../components/ui/atom/HorizontaleSectionSelector.vue' /* webpackChunkName: "components/horizontale-section-selector" */).then(c => wrapFunctional(c.default || c))
export const Icon = () => import('../../components/ui/atom/Icon.vue' /* webpackChunkName: "components/icon" */).then(c => wrapFunctional(c.default || c))
export const Img = () => import('../../components/ui/atom/Img.vue' /* webpackChunkName: "components/img" */).then(c => wrapFunctional(c.default || c))
export const InfoIcon = () => import('../../components/ui/atom/InfoIcon.vue' /* webpackChunkName: "components/info-icon" */).then(c => wrapFunctional(c.default || c))
export const InformationBanner = () => import('../../components/ui/atom/InformationBanner.vue' /* webpackChunkName: "components/information-banner" */).then(c => wrapFunctional(c.default || c))
export const InvisibleFilePicker = () => import('../../components/ui/atom/InvisibleFilePicker.vue' /* webpackChunkName: "components/invisible-file-picker" */).then(c => wrapFunctional(c.default || c))
export const IsEuMarketOpen = () => import('../../components/ui/atom/IsEuMarketOpen.vue' /* webpackChunkName: "components/is-eu-market-open" */).then(c => wrapFunctional(c.default || c))
export const IsUsMarketOpen = () => import('../../components/ui/atom/IsUsMarketOpen.vue' /* webpackChunkName: "components/is-us-market-open" */).then(c => wrapFunctional(c.default || c))
export const Link = () => import('../../components/ui/atom/Link.vue' /* webpackChunkName: "components/link" */).then(c => wrapFunctional(c.default || c))
export const Loader = () => import('../../components/ui/atom/Loader.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/ui/atom/Modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const NavHeaderMobile = () => import('../../components/ui/atom/NavHeaderMobile.vue' /* webpackChunkName: "components/nav-header-mobile" */).then(c => wrapFunctional(c.default || c))
export const PortfolioPrivacyTag = () => import('../../components/ui/atom/PortfolioPrivacyTag.vue' /* webpackChunkName: "components/portfolio-privacy-tag" */).then(c => wrapFunctional(c.default || c))
export const PortfolioRankTag = () => import('../../components/ui/atom/PortfolioRankTag.vue' /* webpackChunkName: "components/portfolio-rank-tag" */).then(c => wrapFunctional(c.default || c))
export const PortfolioSharedTag = () => import('../../components/ui/atom/PortfolioSharedTag.vue' /* webpackChunkName: "components/portfolio-shared-tag" */).then(c => wrapFunctional(c.default || c))
export const PremiumOnlyTag = () => import('../../components/ui/atom/PremiumOnlyTag.vue' /* webpackChunkName: "components/premium-only-tag" */).then(c => wrapFunctional(c.default || c))
export const ProfileImg = () => import('../../components/ui/atom/ProfileImg.vue' /* webpackChunkName: "components/profile-img" */).then(c => wrapFunctional(c.default || c))
export const SectionSelector = () => import('../../components/ui/atom/SectionSelector.vue' /* webpackChunkName: "components/section-selector" */).then(c => wrapFunctional(c.default || c))
export const Spinner = () => import('../../components/ui/atom/Spinner.vue' /* webpackChunkName: "components/spinner" */).then(c => wrapFunctional(c.default || c))
export const StockMarketWidget = () => import('../../components/ui/atom/StockMarketWidget.vue' /* webpackChunkName: "components/stock-market-widget" */).then(c => wrapFunctional(c.default || c))
export const TabSelector = () => import('../../components/ui/atom/TabSelector.vue' /* webpackChunkName: "components/tab-selector" */).then(c => wrapFunctional(c.default || c))
export const Truncate = () => import('../../components/ui/atom/Truncate.vue' /* webpackChunkName: "components/truncate" */).then(c => wrapFunctional(c.default || c))
export const TruncateParagraph = () => import('../../components/ui/atom/TruncateParagraph.vue' /* webpackChunkName: "components/truncate-paragraph" */).then(c => wrapFunctional(c.default || c))
export const UISettingSelect = () => import('../../components/ui/atom/UISettingSelect.vue' /* webpackChunkName: "components/u-i-setting-select" */).then(c => wrapFunctional(c.default || c))
export const VerticalSectionSelector = () => import('../../components/ui/atom/VerticalSectionSelector.vue' /* webpackChunkName: "components/vertical-section-selector" */).then(c => wrapFunctional(c.default || c))
export const Video = () => import('../../components/ui/atom/Video.vue' /* webpackChunkName: "components/video" */).then(c => wrapFunctional(c.default || c))
export const VideoPlayer = () => import('../../components/ui/atom/VideoPlayer.vue' /* webpackChunkName: "components/video-player" */).then(c => wrapFunctional(c.default || c))
export const Tag = () => import('../../components/ui/atom/Tag/Tag.vue' /* webpackChunkName: "components/tag" */).then(c => wrapFunctional(c.default || c))
export const WButton = () => import('../../components/ui/atom/WButton/WButton.vue' /* webpackChunkName: "components/w-button" */).then(c => wrapFunctional(c.default || c))
export const WCard = () => import('../../components/ui/atom/WCard/WCard.vue' /* webpackChunkName: "components/w-card" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
