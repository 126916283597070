export default {
  HANDLE_SHOW_CREATE_POST_MODAL: (state, payload) => {
    state.showCreatePostModal = {
      ...{
        title: '',
        mentionPost: null,
        originalPost: null,
        callback: null,
        dispatch: null,
        context: null,
      },
      ...payload,
      show: state.showCreatePostModal.show,
    };
    state.showCreatePostModal.show = !state.showCreatePostModal.show;
  },
  HANDLE_SHOW_IMAGE_SLIDER_MODAL: (state, payload) => {
    state.showImageSliderModal = {
      navigateTo: 0,
      ...payload,
      show: state.showImageSliderModal.show,
    };
    state.showImageSliderModal.show = !state.showImageSliderModal.show;
  },
  HANDLE_SHOW_CREATE_PORTFOLIO_MODAL: (state, payload) => {
    state.createPortfolioModal.isLeaguePortfolio = !!payload?.leagueId;
    state.createPortfolioModal.leagueId = payload?.leagueId;
    state.createPortfolioModal.league = payload?.league;
    state.createPortfolioModal.invitationId = payload?.invitationId;
    state.createPortfolioModal.show = !state.createPortfolioModal.show;
  },
  HANDLE_SHOW_LEGAL_DISCLAIMER_MODAL: (state) => {
    state.showLegalDisclaimerModal = !state.showLegalDisclaimerModal;
  },
  HANDLE_SHOW_SELECT_PROFILE_MODAL: (state) => {
    state.showSelectProfileModal = !state.showSelectProfileModal;
  },

  HIDE_MENTION_CARD_DONE: (state) => {
    state.currentMentionCard = null;
  },
  MOUSE_ENTER_MENTION_CARD: (state) => {
    state.currentMentionCard = { ...state.currentMentionCard };
  },
  SET_MENTION_HOVERED: (state, isHovered) => {
    state.mentionHovered = isHovered;
  },
  SET_MENTION_HOVERED_ID: (state, id) => {
    state.hoveredMentionId = id;
  },
  SET_MENTION_CARD_HOVERED: (state, isHovered) => {
    state.mentionCardHovered = isHovered;
  },
  SET_MENTION_CARD_HOVERED_ID: (state, id) => {
    state.hoveredMentionCardId = id;
  },
  MOUSE_LEAVE_MENTION_CARD: (state) => {
    state.currentMentionCard = { ...state.currentMentionCard };
  },
  SET_MENTION_CARD_PROFILE: (state, profile) => {
    if (!state.currentMentionCard) {
      state.currentMentionCard = { profile: profile, isLoading: true };
    } else {
      state.currentMentionCard.profile = profile;
      state.currentMentionCard.isLoading = true;
    }
    state.currentMentionCard = { ...state.currentMentionCard };
    state.lastMentionCard = state.currentMentionCard;
  },
  SET_MENTION_CARD_POS: (state, pos) => {
    if (!state.currentMentionCard) {
      state.currentMentionCard = { pos: pos, isLoading: true };
    } else {
      state.currentMentionCard.pos = pos;
      state.currentMentionCard.isLoading = true;
    }
    state.currentMentionCard = { ...state.currentMentionCard };
    state.lastMentionCard = state.currentMentionCard;
  },
  SET_MENTION_CARD_LAST_MENTION_CARD: (state) => {
    state.currentMentionCard = state.lastMentionCard;
  },
  SET_MENTION_CARD_LOADING: (state, isLoading) => {
    state.currentMentionCard.isLoading = isLoading;
    state.currentMentionCard = { ...state.currentMentionCard };
  },
  SET_MENTION_CARD_ERROR: (state, err) => {
    console.error(err);
  },
  SET_MENTION_CARD_IS_FOLLOWED: (state, isFollowed) => {
    state.currentMentionCard.profile.isFollowed = isFollowed;
    state.currentMentionCard = { ...state.currentMentionCard };
  },
  MOUSE_LEAVE_MENTION: (state) => {
    state.currentMentionCard = { ...state.currentMentionCard };
  },
  SET_SHOW_PASSWORD_RESET(state, show) {
    state.passwordResetModal.show = show;
  },
  PASSWORD_RESET_NEXT_STEP(state) {
    state.passwordResetModal.step++;
  },
  INIT_PASSWORD_RESET(state) {
    state.passwordResetModal = {
      show: false,
      step: 1,
    };
  },

  // Add operation modal
  RESET_CREATE_PORTFOLIO_OPERATION_MODAL: (state) => {
    state.createPortfolioOperationModal = {
      show: false,
      portfolioType: null,
      symbol: null,
      portfolio: null,
      operationType: 'Buy',
      symbolLoading: false,
      symbolError: null,
      createOperationError: null,
      createCashOperationError: null,
      tickerValue: null,
      isCashTransaction: false,
      currency: '',
      isSubmitting: false,
      multipleTransactions: false,
      leagueId: null,
      league: null,
      invitationId: null,
    };
  },
  RESET_CREATE_PORTFOLIO_OPERATION_MODAL_ON_OPERATION_CHANGE: (state) => {
    state.createPortfolioOperationModal = {
      portfolioType: null,
      symbol: null,
      portfolio: null,
      operationType: 'Buy',
      symbolLoading: false,
      symbolError: null,
      createOperationError: null,
      createCashOperationError: null,
      tickerValue: null,
      isCashTransaction: false,
      currency: '',
      isSubmitting: false,
      multipleTransactions: false,
    };
  },
  REMOVE_SYMBOL_FROM_PORTFOLIO_OPERATION: (state) => {
    state.createPortfolioOperationModal.symbol = null;
    state.createPortfolioOperationModal.tickerValue = null;
  },
  SUBMIT_PORTFOLIO_OPERATION_FORM: (state, payload) => {
    state.createPortfolioOperationModal.isSubmitting = true;
    state.createPortfolioOperationModal.multipleTransactions = payload;
  },
  RESET_SUBMIT_OPERATION_STATE: (state) => {
    state.createPortfolioOperationModal.isSubmitting = false;
    state.createPortfolioOperationModal.multipleTransactions = false;
  },
  HANDLE_SHOW_CREATE_PORTFOLIO_OPERATION_MODAL: (state, payload) => {
    if ('isCashTransaction' in payload) {
      state.createPortfolioOperationModal.isCashTransaction =
        payload.isCashTransaction;
    } else {
      state.createPortfolioOperationModal.isCashTransaction = false;
    }
    if ('currency' in payload) {
      state.createPortfolioOperationModal.currency = payload.currency;
    }

    if (!state.createPortfolioOperationModal.isCashTransaction) {
      state.createPortfolioOperationModal.symbol = payload.symbol;
      state.createPortfolioOperationModal.tickerValue = payload.tickerValue ?? {
        high: 0,
        low: 0,
      };
    }

    state.createPortfolioOperationModal.show = payload.show;
    if ('shouldShowOperationSwitcher' in payload) {
      state.createPortfolioOperationModal.shouldShowOperationSwitcher =
        payload.shouldShowOperationSwitcher;
    }
    if ('shouldShowOperationButtons' in payload) {
      state.createPortfolioOperationModal.shouldShowOperationButtons =
        payload.shouldShowOperationButtons;
    }
    if ('shouldShowTickerCard' in payload) {
      state.createPortfolioOperationModal.shouldShowTickerCard =
        payload.shouldShowTickerCard;
    }
    state.createPortfolioOperationModal.portfolioType =
      payload.portfolioType ?? 'Stock';
    state.createPortfolioOperationModal.portfolio = payload.portfolio;
    state.createPortfolioOperationModal.operationType =
      payload.operationType ?? 'Buy';
    state.createPortfolioOperationModal.createOperationLoading = false;
    state.createPortfolioOperationModal.isSuggestedTicker =
      payload.isSuggestedTicker ?? false;
    state.createPortfolioOperationModal.stocksPage =
      payload.stocksPage ?? false;
  },
  HANDLE_SET_HELP_CENTER: (state, payload) => {
    state.helpCenterObjectRes = { ...payload };
  },
  HANDLE_SET_SHOW_PROFILE_CARD: (state, payload) => {
    state.showProfileCardRes = { ...payload };
  },
  SET_OPERATION_SYMBOL(state, payload) {
    state.createPortfolioOperationModal.symbol = payload;
  },
  GET_TICKER_VALUE_INIT(state) {
    state.createPortfolioOperationModal.tickerValue = null;
  },
  GET_TICKER_VALUE_SUCCESS(state, payload) {
    state.createPortfolioOperationModal.tickerValue = payload;
  },
  SET_CREATE_OPERATION_MODAL_PORTFOLIO(state, portfolio) {
    state.createPortfolioOperationModal.portfolio = portfolio;
  },

  // Portfolio empty state modal
  SHOW_PORTFOLIO_EMPTY_STATE_MODAL(state) {
    state.portfolioEmptyStateModal.show = !state.portfolioEmptyStateModal.show;
  },

  // Giphy Modal
  SET_LAST_GIPHY_TRENDING_SEARCH(state) {
    state.giphyModal.lastGiphyTrendingSearch = this.$dayjs();
  },

  // Compare Tickers Modal
  SET_COMPARE_TICKERS_MODAL(state, payload) {
    state.compareTickersModal.show = payload.show;
    state.compareTickersModal.numberOfItemsInComparison =
      payload.numberOfItemsInComparison;
    state.compareTickersModal.selectedTicker = payload.selectedTicker;
  },

  // Team Portfolio Share Modal
  TOGGLE_SHOW_PORTFOLIO_SHARE_MODAL(state) {
    state.manageTeamPortfolioModal.show = !state.manageTeamPortfolioModal.show;
  },

  // Compare Portfolios Modal
  SET_COMPARE_PORTFOLIOS_MODAL(state, payload) {
    state.comparePortfoliosModal.show = payload.show;
    if (state.comparePortfoliosModal.show) {
      state.comparePortfoliosModal.isMyPortfolio = payload.isMyPortfolio;
      state.comparePortfoliosModal.numberOfItemsInComparison =
        payload.numberOfItemsInComparison;
    } else {
      state.comparePortfoliosModal.isMyPortfolio = false;
      state.numberOfItemsInComparison = 0;
    }
  },

  // Create League Modal
  TOGGLE_SHOW_CREATE_LEAGUE_MODAL(state) {
    state.createLeagueModal.show = !state.createLeagueModal.show;
  },

  HIDE_ALL_MODALS(state) {
    (state.showMobileSidebar = false), (state.showCreatePostModal.show = false);
    state.showImageSliderModal.show = false;
    state.showSelectProfileModal = false;
    state.createPortfolioModal.show = false;
    state.showLegalDisclaimerModal = false;
    state.passwordResetModal.show = false;
    state.createPortfolioOperationModal.show = false;
    state.helpCenterObjectRes.show = false;
    state.portfolioEmptyStateModal.show = false;
    state.giphyModal.show = false;
    state.compareTickersModal.show = false;
    state.comparePortfoliosModal.show = false;
    state.manageTeamPortfolioModal.show = false;
    state.createLeagueModal.show = false;
  },
  SHOW_PORTFOLIO_ASSET_OPERATION_MODAL(state, payload) {
    state.showPortfolioAssetOperationModalFooter = payload;
  },

  CLOSE_EMAIL_CONFIRMATION_BANNER(state) {
    state.hasClosedEmailConfirmationBanner = true;
  },
};
