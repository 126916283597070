import { MutationsCreator } from '@/store/utils';
import { showErrorToast } from '~/utils/toast-notifications';

export default {
  // Profile
  INIT_PROFILE: (state) => {
    state.profile = null;
    state.contentStep = 0;
    state.profileLoading = false;
    state.profileError = null;
    state.contentStep = 0;
    state.followers = [];
    state.followersError = null;
    state.followersLoading = false;
    state.followersPage = 1;
    state.nextFollowersLoading = false;
    state.followed = [];
    state.newsSuscribedLanguages = [];
    state.followedError = null;
    state.followedLoading = false;
    state.followedPage = 1;
    state.nextFollowedLoading = false;
    state.hasSeenWTPModal = false;
    state.blocks = null;
    state.reports = null;
  },
  SET_PROFILE: (state, payload) => {
    state.profile = payload;
  },
  SET_PROFILE_FOLLOW: (state, payload) => {
    if (!state.profile || state.profile.profileId !== payload.profileId) {
      return;
    }
    state.profile.isFollowed = payload.isFollowed;
  },
  SET_CONTENT_STEP: (state, step) => {
    state.contentStep = step;
  },
  SET_WTPMODAL_SEEN: (state) => {
    state.hasSeenWTPModal = true;
    state.profile.hasSeenWTPModal = true;
  },
  // Unused, we set the whole profile instead
  TOGGLE_PROFILE_FOLLOW: (state, payload) => {
    state.profile.isFollowed = payload.isFollowed;
  },
  SET_PROFILE_LOADING: (state, isLoading) => {
    state.profileLoading = isLoading;
  },
  TOGGLE_PROFILE_EDIT_MODAL: (state) => {
    state.showProfileEditModal = !state.showProfileEditModal;
  },
  SET_PROFILE_ERROR: (state, error) => {
    state.profileError = error;
    showErrorToast(window.$nuxt.$t('error_loading_profile'));
  },
  SET_PROFILE_CARD_ERROR: (state, error) => {
    state.profileCardError = error;
    showErrorToast(window.$nuxt.$t('error_loading_profile_card'));
  },
  // Profile Feed
  INIT_FEED: (state) => {
    state.feed = [];
    state.feedLoading = false;
    state.feedError = null;
  },
  SET_FEED: (state, payload) => {
    state.feed = payload;
  },
  SET_FEED_LOADING: (state, isLoading) => {
    state.feedLoading = isLoading;
  },
  SET_NEXT_POST_LOADING: (state, isLoading) => {
    state.nextPostLoading = isLoading;
  },
  SET_FEED_ERROR: (state, error) => {
    state.postError = error;
    showErrorToast(window.$nuxt.$t('error_loading_profile_feed'));
  },
  INCREMENT_REPLYCOUNT: (state, postId) => {
    if (state.feed) {
      const index = state.feed.findIndex((e) => e.postId === postId);

      if (index != -1) {
        state.feed[index].replyCount++;
      }
    }
  },
  INCREMENT_REPOSTCOUNT: (state, postId) => {
    if (state.feed) {
      const index = state.feed.findIndex((e) => e.postId === postId);

      if (index != -1) {
        state.feed[index].repostCount++;
      }
    }
  },
  APPEND_POSTS_TO_FEED: (state, payload) => {
    state.feed = [...state.feed, ...payload];
  },
  APPEND_NEW_POST_AT_TOP: (state, post) => {
    if (state.feed) state.feed = [post, ...state.feed];
  },
  UPDATE_POST: (state, payload) => {
    if (state.feed) {
      const index = state.feed.findIndex((e) => e.postId === payload.postId);

      if (index !== -1) {
        const res = [...state.feed];

        res[index] = payload;
        state.feed = res;
      }
    }
  },
  // Profile Followers
  SET_FOLLOWERS: (state, followers) => {
    state.followers = followers;
  },
  APPEND_FOLLOWERS: (state, followers) => {
    state.followers = [...state.followers, ...followers];
  },
  SET_FOLLOWERS_LOADING: (state, isLoading) => {
    state.followersLoading = isLoading;
  },
  SET_NEXT_FOLLOWERS_LOADING: (state, isLoading) => {
    state.nextFollowersLoading = isLoading;
  },
  RESET_FOLLOWERS_PAGE: (state) => {
    state.followersPage = 1;
  },
  INCREASE_FOLLOWERS_PAGE: (state) => {
    state.followersPage++;
  },
  UPDATE_FOLLOWER_IS_FOLLOWED: (state, follower) => {
    if (state.followers) {
      const index = state.followers.findIndex(
        (f) => f.profileId === follower.profileId
      );

      if (index != -1) {
        state.followers[index].isFollowed = follower.isFollowed;
      }
    }
  },
  SET_SHOW_LOAD_MORE_FOLLOWERS: (state, show) => {
    state.showLoadMoreFollowers = show;
  },
  // Profile Followed
  SET_FOLLOWED: (state, followed) => {
    state.followed = followed;
  },
  APPEND_FOLLOWED: (state, followed) => {
    state.followed = [...state.followed, ...followed];
  },
  SET_FOLLOWED_LOADING: (state, isLoading) => {
    state.followedLoading = isLoading;
  },
  SET_NEXT_FOLLOWED_LOADING: (state, isLoading) => {
    state.nextFollowedLoading = isLoading;
  },
  RESET_FOLLOWED_PAGE: (state) => {
    state.followedPage = 1;
  },
  INCREASE_FOLLOWED_PAGE: (state) => {
    state.followedPage++;
  },
  UPDATE_FOLLOWED_IS_FOLLOWED: (state, followed) => {
    if (state.followed) {
      const index = state.followed.findIndex(
        (f) => f.profileId === followed.profileId
      );

      if (index != -1) {
        state.followed[index].isFollowed = followed.isFollowed;
      }
    }
  },
  SET_SHOW_LOAD_MORE_FOLLOWED: (state, show) => {
    state.showLoadMoreFollowed = show;
  },
  SET_AVERAGE_RATING: (state, rating) => {
    state.profile.averageRating = rating;
  },
  SET_RATING_COUNT: (state, count) => {
    state.profile.ratingCount = count;
  },
  SET_FOLLOWED_PROFILES_COUNT_LOADING: (state, value) => {
    state.followedCountPerType.loading = value;
  },
  SET_FOLLOWED_PROFILES_COUNT: (state, value) => {
    state.followedCountPerType.data = value;
  },
  SET_FOLLOWED_PROFILES_COUNT_ERROR: (state, error) => {
    state.followedCountPerType.error = error;
  },
  /// Post Replies
  SET_REPLIES_LOADING: (state, payload) => {
    const post = state.feed.find((e) => e.postId === payload.target);
    if (post) {
      post.repliesLoading = payload.isLoading;
    }
  },
  SET_REPLIES_ERROR: (state, payload) => {
    const post = state.feed.find((e) => e.postId === payload.target);
    if (post) {
      post.repliesError = payload.err;
      //TODO : add some toast
    }
  },
  APPEND_NEW_REPLY: (state, payload) => {
    if (state.feed) {
      // Finding the post
      const post = state.feed.find((e) => e.postId === payload.target);
      if (post) {
        if (!post.replies) {
          post.replies = [];
        }
        const newArray = post.replies.slice(0, 1);
        post.replies = [payload, ...newArray];
        post.replyCount++;
      }
    }
  },
  UPDATE_REPLY: (state, data) => {
    if (state.feed) {
      // Finding the post
      const post = state.feed.find((e) => e.postId === data.target);
      if (post) {
        const index = post.replies.findIndex((e) => e.postId === data.postId);
        const res = [...post.replies];
        res[index] = data;

        post.replies = res;
      }
    }
  },
  INCREMENT_REPLY_REPLY_COUNT: (state, payload) => {
    if (state.feed) {
      // Finding the post
      const post = state.feed.find((e) => e.postId === payload.postId);
      if (post) {
        const index = post.replies.findIndex(
          (e) => e.postId === payload.replyId
        );

        post.replies[index].replyCount++;
      }
    }
  },
  UPDATE_POST_LIKE: (state, payload) => {
    if (state.feed) {
      const post = state.feed.find((e) => e.postId === payload.postId);

      if (post) {
        post.isLiked = payload.isLiked;
        post.likeCount = payload.likeCount;
      }
    }
  },
  UPDATE_POST_FAVORITE: (state, payload) => {
    if (state.feed) {
      const post = state.feed.find((e) => e.postId === payload.postId);

      if (post) {
        post.isFavorited = payload.isFavorited;
      }
    }
  },
  /// Nested Replies
  SET_NESTED_REPLIES: (state, payload) => {
    if (state.feed) {
      const post = state.feed.find((e) => e.postId === payload.postId);

      if (post) {
        const reply = post.replies.find((e) => e.postId === payload.replyId);

        if (reply) {
          reply.replies = payload.replies;
        }
      }
    }
  },
  APPEND_NESTED_REPLY_BOTTOM: (state, payload) => {
    if (state.feed) {
      const post = state.feed.find((e) => e.postId === payload.postId);

      if (post) {
        const reply = post.replies.find((e) => e.postId === payload.replyId);

        if (reply) {
          reply.replies = [...reply.replies, ...payload.replies];
        }
      }
    }
  },
  APPEND_NESTED_REPLY_TOP: (state, payload) => {
    if (state.feed) {
      const post = state.feed.find((e) => e.postId === payload.postId);

      if (post) {
        const reply = post.replies.find(
          (e) => e.postId === payload.data.target
        );

        if (reply) {
          if (!reply.replies) {
            reply.replies = [payload.data];
          } else {
            reply.replies = [payload.data, ...reply.replies];
          }
        }
      }
    }
  },
  UPDATE_NESTED_REPLY: (state, payload) => {
    if (state.feed) {
      // Finding the post
      const post = state.feed.find((e) => e.postId === payload.postId);
      if (post) {
        const reply = post.replies.find(
          (e) => e.postId === payload.data.target
        );

        if (reply) {
          const index = reply.replies.findIndex(
            (e) => e.postId === payload.data.postId
          );
          const res = [...reply.replies];
          res[index] = payload.data;

          reply.replies = res;
        }
      }
    }
  },
  INCREMENT_NESTED_REPLY_REPLY_COUNT: (state, payload) => {
    if (state.feed) {
      // Finding the post
      const post = state.feed.find((e) => e.postId === payload.postId);
      if (post) {
        const reply = post.replies.find((e) => e.postId === payload.replyId);

        if (reply) {
          const nestedReply = reply.replies.find(
            (e) => e.postId === payload.nestedReplyId
          );
          if (nestedReply) nestedReply.replyCount++;
        }
      }
    }
  },
  DELETE_PROFILE_INIT: (state) => {
    state.deleteProfile = { data: null, loading: true, error: null };
  },
  DELETE_PROFILE_SUCCESS: (state) => {
    state.deleteProfile = { data: true, loading: false, error: null };
  },
  DELETE_PROFILE_ERROR: (state, err) => {
    state.deleteProfile = { data: null, loading: false, error: err };
  },
  ...MutationsCreator([
    {
      name: 'CREATE_PROFILE',
      state: 'createProfile',
    },
  ]),
  ADD_TO_REPORT_LIST: (state, payload) => {
    state.reports = payload.data;
  },
  UPDATE_REPORT_LIST: (state, payload) => {
    state.reports.push(payload.data);
  },
  ADD_TO_BLOCK_LIST: (state, payload) => {
    state.blocks = payload.data;
  },
  UPDATE_BLOCK_LIST: (state, payload) => {
    state.blocks.push(payload);
  },
  REMOVE_FROM_BLOCK_LIST: (state, payload) => {
    state.blocks = state.blocks.filter((b) => b.id !== payload.id);
  },
};
